#contact {
    background-color: #f8f9fa; /* Light background */
    color: #333;
  }
  
  #contact .section-title {
    color: #f39c12; /* Accent color */
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  #contact-form .form-control {
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1rem;
    padding: 0.75rem;
    margin-bottom: 10px;
  }
  
  #contact-form .btn-primary {
    background-color: #f39c12;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
  }
  
  #contact-form .btn-primary:hover {
    background-color: #e67e22; /* Darker hover state */
  }
  
/* NavBar.css */

.navbar {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #333;
    z-index: 1000;
    padding: 10px 20px 0px;
    display: flex;
    justify-content: flex-end;
}

.navbar .nav-links {
    list-style-type: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0px; 
}

.navbar .nav-links li {
    display: inline;
}

.navbar .nav-links li p {
    color: white;
    text-decoration: none;
    font-size: 1.4rem;
    transition: color 0.3s ease;
    cursor: pointer;
}

.navbar .nav-links li p:hover {
    color: #f0a500;
}

  
@media (max-width: 768px) {
    .navbar .nav-links {
        flex-direction: row;
        gap: 15px;
    }
    .navbar .nav-links li p {
        font-size: 1.0rem;
    }
}
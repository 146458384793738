
#services {
    background-color: #fff;
}
  
  #services .section-title {
    color: #5bc0de; /* Bright, eye-catching title */
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
  }
  
  #services p {
    color: black;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .service-card {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    transition: transform 0.3s ease;
  }
  
  .service-card:hover {
    transform: scale(1.05);
  }
  
  .service-card img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .service-card h4 {
    font-size: 2rem;
    color: #5bc0de;
    margin-top: 15px;
  }

#about {
    background-color: #f9f9f9;
}

#about .section-title {
    color: #d9534f; /* Vibrant color for title */
    font-family: 'Poppins', sans-serif; /* Modern font for text */
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
}

#about p {
    font-size: 1.1rem;
    color: #333;
    line-height: 1.6;
}

#about .btn {
    background-color: #d9534f;
    border: none;
    font-size: 1rem;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
}

#about .btn:hover {
    background-color: #c9302c;
}
#location {
    background-color: #fff;
  }
  
  #location .section-title {
    color: #5bc0de; /* Bright cyan for the section title */
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
  }
  
  #location p {
    color: #666;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }

#gallery {
    background-color: #f8f9fa;
  }
  
  #gallery .section-title {
    color: #5bc0de; /* Bright cyan for the section title */
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
  }
  
  #gallery p {
    color: #666;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }
  
  .gallery-item {
    overflow: hidden;
    position: relative;
  }
  
  .gallery-item img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  .gallery-item:hover img {
    transform: scale(1.1); /* Slight zoom-in effect on hover */
  }

  #testimonials {
    background-color: #343a40; /* Dark background for contrast */
    color: #fff; /* White text for readability */
  }
  
  #testimonials .section-title {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  #testimonials p {
    color: #f8f9fa;
    font-size: 1.1rem;
  }
  
  .testimonial {
    background-color: #495057; /* Slightly lighter dark background for testimonials */
    padding: 1.5rem;
    border-radius: 10px;
    margin-bottom: 1.5rem;
  }
  
  .testimonial-text {
    font-style: italic;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .client-name {
    color: #f39c12;
    font-weight: bold;
  }
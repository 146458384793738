body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f9f9f9;
}

.hero-section {
    background-image: url('../images/landing-page.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.hero-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
}

.container {
    position: relative;
    z-index: 1;
}

.hero-title {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #fff;
    text-shadow: 2px 2px 4px black;

}

.hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 30px;
    color: #fff;
    text-shadow: 2px 2px 4px black;
}

.hero-btn {
    padding: 15px 30px;
    font-size: 1.2rem;
    background-color: #f39c12; /* Vibrant gold color */
    cursor: pointer;
    border: none;
    color: #fff;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-shadow: 2px 2px 4px black;
}

.hero-btn:hover {
    cursor: pointer;
    background-color: #e67e22; /* Darker gold on hover */
}

@media (max-width: 768px) {
    .hero-title {
        font-size: 2.5rem;
    }

    .hero-subtitle {
        font-size: 1.2rem;
    }

    .hero-btn {
        font-size: 1rem;
    }
}